import store from '.'
import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import router from '@/router'
import http from '@/services'
import { Menu, Module as ModuleModel } from '@/Models'
import { StoreNames } from '@/config'

@Module({ generateMutationSetters: true })
class MenuStore extends VuexModule {
  private _loading = false
  private _routes_empy_layout = ['/', '', '/CMS', '/cms', '/firstLogin']
  private _routes_single_layout = [
    '/home',
    '/hoshin',
    '/matrices',
    '/matrix',
    '/show_matrix',
    '/mci',
    '/usersList',
    '/departmentsList',
    '/EmployedsList',
    '/companysList',
    '/about',
    '/aboutUs',
    '/BSC',
    '/ReporteBSC',
    '/article',
    '/articleDetail',
    '/matrixRelationship',
  ]
  private _menu: string | null = localStorage.getItem(StoreNames.USER_MENU)

  get loadinState() {
    return this._loading
  }

  get currentTabComponent(): string {
    if (
      this._routes_empy_layout.includes(router.app.$route.path) ||
      router.app.$route.name == '404'
    )
      return 'EmpyLayout'
    if (this._routes_empy_layout.includes(router.app.$route.path))
      return 'EmpyLayout'
    if (this._routes_single_layout.includes(router.app.$route.path))
      return 'SingleLayout'
    return 'BoxLayout'
  }

  get user_modules(): ModuleModel[] {
    if (!this._menu) return []
    const menu = JSON.parse(this._menu) as Menu
    return menu.modules.filter((m) => m.visible_in_menu && m.pages_exist)
  }

  get user_permissions(): string[] {
    if (!this._menu) return []
    const menu = JSON.parse(this._menu) as Menu
    return menu.permissions
  }

  private isAllowed(permission: string): boolean {
    return this.user_permissions.includes(permission)
  }

  isAllowedAction(permissions: Array<string> | string): boolean {
    if (permissions instanceof Array)
      return permissions.every((permission) => this.isAllowed(permission))
    else return this.isAllowed(permissions)
  }

  @Mutation
  setLoding(paylopad: boolean) {
    this._loading = paylopad
  }

  @Mutation
  clear() {
    this._menu = null
    localStorage.removeItem(StoreNames.USER_MENU)
  }

  @Mutation
  setMenu(payload: Menu) {
    this._menu = JSON.stringify(payload)
    localStorage.setItem(StoreNames.USER_MENU, this._menu)
  }

  @Action
  async initMenu(role: string) {
    const response = await http.get(`account/user_menu/${role}/`)

    this.setMenu(response.data)
    return Promise.resolve(response.data)
  }
}

const menuModule = new MenuStore({ store, name: 'menu' })
export default menuModule
