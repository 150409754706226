import store from '.'
import { Module, Mutation, VuexModule, Action } from 'vuex-class-modules'
import { StoreNames } from '@/config'
import { Breadcrumb } from '@/Models/Breadcrumb'
import { breadcrumb } from '@/services'

import BreadCrumb from '@/components/BreadCrumb/BreadCrumb'

@Module()
class moduleBradCrumb extends VuexModule {
  private _bread_crumbs: Breadcrumb[] = JSON.parse(
    localStorage.getItem(StoreNames.BREADCRUMBLIST) ?? '[]',
  )

  get breadcrumbList(): Breadcrumb[] {
    return this._bread_crumbs
  }

  @Mutation
  clear() {
    this._bread_crumbs = []
    localStorage.setItem(
      StoreNames.BREADCRUMBLIST,
      JSON.stringify(this._bread_crumbs),
    )
  }

  @Mutation
  setRoute(payload: Breadcrumb) {
    this._bread_crumbs.push(payload)
    localStorage.setItem(
      StoreNames.BREADCRUMBLIST,
      JSON.stringify(this._bread_crumbs),
    )
  }
  @Mutation
  remove(payload: string) {
    this._bread_crumbs.forEach((element: Breadcrumb, index: number) => {
      if (element.link == payload) {
        this._bread_crumbs.splice(index, 1)
        return
      }
    })
    localStorage.setItem(
      StoreNames.BREADCRUMBLIST,
      JSON.stringify(this._bread_crumbs),
    )
  }
  @Action
  validateSetRoute(payload: Breadcrumb) {
    const list: Breadcrumb[] = this._bread_crumbs
    const pathList = list.map((x: Breadcrumb) => x.link)
    console.log(pathList, 'GGGG')
    if (pathList.includes(payload.link)) {
      this.remove(payload.link)
    } else {
      this.setRoute(payload)
    }
  }
}
const BreadCrumbModule = new moduleBradCrumb({ store, name: 'breadcrumb' })
export default BreadCrumbModule
