import {
  required,
  confirmed,
  length,
  email,
  numeric,
  integer,
  alpha,
  alpha_num,
  alpha_dash,
  alpha_spaces,
  min,
  max,
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { i18n } from '@/plugins/i18n'

extend('required', {
  ...required,
  message: i18n.t('validates.required_validate').toString(),
})

extend('email', {
  ...email,
  message: i18n.t('validates.email_validate').toString(),
})

extend('confirmed', {
  ...confirmed,
  message: i18n.t('validates.confirmed_validate').toString(),
})

extend('length', {
  ...length,
  message: i18n.t('validates.length_validate').toString(),
})

extend('password_validate', {
  validate(value) {
    return /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(value)
  },
  message: i18n.t('validates.password_validate_format').toString(),
})

extend('password', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target
  },
  message: i18n.t('validates.password_validate').toString(),
})

extend('numeric', {
  ...numeric,
  message: i18n.t('validates.numeric_validate').toString(),
})

extend('integer', {
  ...integer,
  message: i18n.t('validates.integer_validate').toString(),
})

extend('alpha', {
  ...alpha,
  message: i18n.t('validates.alpha_validate').toString(),
})

extend('alpha_num', {
  ...alpha_num,
  message: i18n.t('validates.alpha_num_validate').toString(),
})

extend('alpha_dash', {
  ...alpha_dash,
  message: i18n.t('validates.alpha_dash_validate').toString(),
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: i18n.t('validates.alpha_spaces_validate').toString(),
})

extend('minmax', {
  validate(value, { min, max }: any) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: i18n.t('validates.minmax_validate').toString(),
})

extend('minValue', {
  validate(value, { min }: any) {
    if (Number.isInteger(parseInt(value))) {
      const num = parseInt(value)
      return num >= min
    }
    return false
  },
  params: ['min'],
  message: i18n.t('validates.minValue_validate').toString(),
})

extend('maxValue', {
  validate(value, { max }: any) {
    if (Number.isInteger(parseInt(value))) {
      const num = parseInt(value)
      return num <= max
    }
    return false
  },
  params: ['max'],
  message: i18n.t('validates.maxValue_validate').toString(),
})

extend('between_date', {
  validate(value, { min, max }: any) {
    const time = new Date(value).getTime()
    if (min != '0' && max != '0')
      return time >= new Date(min).getTime() && time <= new Date(max).getTime()
    if (min != '0' && max == '0') return time >= new Date(min).getTime()
    if (min == '0' && max != '0') return time <= new Date(max).getTime()
    return false
  },
  params: ['min', 'max'],
  message: i18n.t('validates.between_date_validate').toString(),
})

extend('min', {
  ...min,
  params: ['length'],
  message: i18n.t('validates.min_validate').toString(),
})

extend('max', {
  ...max,
  params: ['length'],
  message: i18n.t('validates.max_validate').toString(),
})
