import store from '.'
import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import { StoreNames } from '@/config'

@Module({ generateMutationSetters: true })
class CollectionStore extends VuexModule {
  private _collection_id: number | null = localStorage.getItem(
    StoreNames.COLLECTION_ID,
  )
    ? Number(localStorage.getItem(StoreNames.COLLECTION_ID))
    : null

  get collection_id(): number | null {
    return this._collection_id
  }

  @Mutation
  setCollectionId(payload: number) {
    this._collection_id = payload
    localStorage.setItem(
      StoreNames.COLLECTION_ID,
      this._collection_id.toString(),
    )
  }

  @Mutation
  clear() {
    this._collection_id = null
    localStorage.removeItem(StoreNames.COLLECTION_ID)
  }
}

const collectionModule = new CollectionStore({ store, name: 'collection' })
export default collectionModule
