import { RouteConfig } from 'vue-router'

const CMSRoutes: Array<RouteConfig> = [
  {
    path: '/cms',
    name: 'CMS',

    meta: { authorize: ['CMS', 'MEC'] },
    component: () => import('@/views/CMS/index.vue'),
    props: (route) => ({ matrix_id: route.params.matrix_id }),
  },
]

export default CMSRoutes
