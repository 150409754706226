import { RouteConfig } from 'vue-router'

const HoshinrRoutes: Array<RouteConfig> = [
  {
    path: '/hoshin',
    name: 'hoshin',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(/* webpackChunkName: "hoshin" */ '@/views/hoshin/Index.vue'),
  },
  {
    path: '/matrices',
    name: 'matrices',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(/* webpackChunkName: "matrices" */ '@/views/hoshin/Matrices.vue'),
  },
  {
    path: '/article',
    name: 'article',
    meta: { authorize: ['MEC', 'CMS'] },
    component: () =>
      import(/* webpackChunkName: "matrices" */ '@/views/Article/Article.vue'),
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ '@/views/Usuarios/Usuarios.vue'
      ),
  },

  {
    path: '/matrix',
    name: 'matrix',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(/* webpackChunkName: "matrix" */ '@/views/Matrix/Matrix.vue'),
  },
  {
    path: '/show_matrix',
    name: 'show_matrix',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(
        /* webpackChunkName: "show-matrix" */ '@/views/Matrix/ShowMatrix.vue'
      ),
  },
  {
    path: '/matrixRelationship',
    name: 'matrixRelationship',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(
        /* webpackChunkName: "show-matrix" */ '@/views/MatrixRelationship/MatrixRelationship.vue'
      ),
  },
  {
    path: '/mci',
    name: 'Mci',
    meta: { authorize: ['MEC'] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "mci" */ '@/views/Mci/Mci.vue'),
  },
  {
    path: '/BSC',
    name: 'BSC',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(/* webpackChunkName: "hoshin" */ '@/views/BSC/BSC.vue'),
  },
  {
    path: '/ReporteBSC',
    name: 'ReporteBSC',
    meta: { authorize: ['MEC'] },
    component: () =>
      import(/* webpackChunkName: "hoshin" */ '@/views/ReporteBSC/index.vue'),
  },
]

export default HoshinrRoutes
