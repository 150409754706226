import { i18n } from '@/plugins/i18n'
import authModule from '@/store/auth'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HoshinRoutes from './hoshinroutes'
import UserRoute from './usersroutes'
import EmployedRoute from './employedsroutes'
import CompanyRoute from './companysroutes'
import cmsroutes from '@/router/cmsroutes'
import DepartmentRoute from './departmentsroutes'
import AboutUs from './aboutusroutes'
import moduleBreadCrumb from '@/store/moduleBreadCrumb'
import menuModule from '@/store/menu'
Vue.use(VueRouter)

const base_routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue'),
  },
  {
    path: '/firstLogin',
    name: 'firstLogin',
    meta: { authorize: ['CMS', 'MEC'] },
    component: () => import('@/views/firtsLogin/index.vue'),
    beforeEnter: (to, from, next) => {
      const user = authModule.user

      if (from.path == '/') {
        next()
      } else {
        console.log('No puedes acceder la vista ')
      }
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/About/index.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/AboutUs/index.vue'),
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    meta: { authorize: ['MEC', 'CMS'] },
    component: () => import('@/views/ArticleDetail/index.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/index.vue'),
  },
  {
    path: '/matrixRelationship',
    name: 'matrixRelationship',
    meta: { authorize: ['MEC'] },
    component: () =>
      import('@/views/MatrixRelationship/MatrixRelationship.vue'),
  },
]

const routes = base_routes.concat(
  HoshinRoutes,
  UserRoute,
  EmployedRoute,
  CompanyRoute,
  cmsroutes,
  DepartmentRoute,
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!authModule.isAuthenticated) {
//       next({
//         path: '/',
//         query: {
//           nextUrl: to.fullPath,
//           message: i18n.t('toast.session_off').toString(),
//         },
//         replace: true,
//       })
//     } else next()
//   } else next()
// })

router.beforeEach((to, from, next) => {
  menuModule.setLoding(false)
  const authorize: string[] = to.meta?.authorize
  //se verifica que el usuario no sea un objeto vacio
  const currentUser =
    authModule.user &&
    Object.keys(authModule.user).length === 0 &&
    Object.getPrototypeOf(authModule.user) === Object.prototype
      ? false
      : authModule.user

  //se verifica si la ruta esta restringida por roles
  if (authorize) {
    //se deben optener los datos del usuario de lo contrario se redirige al login
    if (!currentUser) {
      console.log('No hay datos de usuario')
      return next({
        path: '/',
        query: {
          returnUrl: to.path,
        },
      })
    }
    // se verifica si el usuario tiene el rol permitido para acceder ala ruta
    const roles = currentUser.groups.map((x: any) => x.name)
    //por cada rol autorizado
    const canAccess = authorize.some((x: string) => roles.includes(x))
    // Si se encuntra dentro de los roles permitidos se redirige a una nueva ruta
    console.log('autorize', authorize, 'roles', roles, 'canAccess', canAccess)
    if (!canAccess) {
      // Si no se encuntra dentro de los roles permitidos se redirige a una nueva ruta
      return next({ path: '/' })
    }
    if (localStorage['authToken'] == undefined) {
      console.log('No hay token')
      return next({ path: '/' })
    }
  }
  moduleBreadCrumb.remove(to.path)
  next()
})

export default router
