import { Component, Vue } from 'vue-property-decorator'
import menuModule from '@/store/menu'

@Component({
  components: {},
})
export default class Loader extends Vue {
  get isLoading() {
    return menuModule.loadinState
  }
}
