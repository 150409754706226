export const StoreNames = Object.freeze({
  USER_MENU: 'user_menu',
  AUTH_TOKEN: 'authToken',
  USER: 'user',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  PROFILE: 'profile',
  COLLECTION_ID: 'collection_id',
  MATRIX: 'matrix',
  QUADRANT: 'quadrant',
  TERMS: 'terms',
  COLLECTION_NAME: 'collection_name',
  BREADCRUMBLIST: 'bread_crumb_list',
  ARTICLE: 'article',
  QUADRANT_CONTENT_ID: 'quadrant_content_id',
  CMSMATRIX: 'cmsmatrix',
  CMSQUADRANT: 'cmsquadrant',
  CMSCONTENTID: 'cmscontentid',
  ARTICLEENDPOINT: 'articleendpoint',
  CMSQUADRANTNAME: 'cmsquadrantname',
})
