import { RouteConfig } from 'vue-router'

const EmployedRoutes: Array<RouteConfig> = [
  {
    path: '/employedsList',
    name: 'employedsList',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/EmployedsList/Employeds.vue'),
  },
]

export default EmployedRoutes
