import { RouteConfig } from 'vue-router'

const UsersRoutes: Array<RouteConfig> = [
  {
    path: '/usersList',
    name: 'usersList',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/UsersList/Users.vue'),
  },
]

export default UsersRoutes
