import { RouteConfig } from 'vue-router'

const CompanysRoutes: Array<RouteConfig> = [
  {
    path: '/companysList',
    name: 'companysList',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/CompanysList/CompanysList.vue'),
  },
]

export default CompanysRoutes
