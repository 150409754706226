import { RouteConfig } from 'vue-router'

const DepartmentRoute: Array<RouteConfig> = [
  {
    path: '/departmentsList',
    name: 'departmentsList',
    meta: { authorize: ['MEC'] },
    component: () => import('@/views/DepartmentList/index.vue'),
  },
]

export default DepartmentRoute
