import store from '.'
import { Module, Mutation, VuexModule } from 'vuex-class-modules'
import { StoreNames } from '@/config'
import { Matrix, Quadrant } from '@/Models'

@Module({ generateMutationSetters: true })
class MatrixStore extends VuexModule {
  private _matrix: Matrix | null = localStorage.getItem(StoreNames.MATRIX)
    ? localStorage.getItem(StoreNames.MATRIX)
      ? (JSON.parse(localStorage.getItem(StoreNames.MATRIX) || '{}') as Matrix)
      : null
    : null
  private _edit = false
  private _quadrant: any = JSON.parse(
    localStorage.getItem(StoreNames.QUADRANT) ?? '{}',
  ) as Quadrant

  private _quadrantName: any =
    localStorage.getItem(StoreNames.CMSQUADRANT) ?? ''

  get matrix(): Matrix | null {
    return this._matrix
  }

  get quadrant() {
    return this._quadrant
  }

  get quadrantName(): string | null {
    return this._quadrantName.replace(/['"]+/g, '')
  }

  get isEdit(): boolean {
    return this._edit
  }

  @Mutation
  setMatrix(payload: any) {
    this._matrix = { ...this._matrix, ...payload } as Matrix
    localStorage.setItem(StoreNames.MATRIX, JSON.stringify(this._matrix))
  }

  @Mutation
  clear() {
    this._edit = false
    this._matrix = null
    localStorage.removeItem(StoreNames.MATRIX)
  }

  @Mutation
  setQuadrantName(quadrantName: any) {
    this._quadrantName = quadrantName
    localStorage.setItem(StoreNames.CMSQUADRANT, JSON.stringify(quadrantName))
  }

  @Mutation
  setQuadrant(quadrant: any) {
    this._quadrant = { ...this._quadrant, ...quadrant } as Quadrant
    localStorage.setItem(StoreNames.QUADRANT, JSON.stringify(quadrant))
  }

  @Mutation
  setActionMode(payload: boolean) {
    this._edit = payload
  }
}

const matrixModule = new MatrixStore({ store, name: 'matrix' })
export default matrixModule
