import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { ValidationProvider } from 'vee-validate'
import Buefy from 'buefy'
import { i18n } from '@/plugins/i18n'
import './vee-validate'
import '@mdi/font/css/materialdesignicons.css'
import 'vue2-datepicker/locale/es'
import jQuery from 'jquery'

declare global {
  interface Window {
    jQuery: any
  }
}
window.jQuery = require('jquery')
Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Buefy)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
