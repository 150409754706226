import store from '.'
import { Module, Mutation, Action, VuexModule } from 'vuex-class-modules'
import http from '@/services'
import { StoreNames } from '@/config'
import { Login, Token } from '@/Models'
import menuModule from './menu'
import collectionModule from './collection'
import matrixModule from './matrix'
import router from '../router'
import { i18n } from '@/plugins/i18n'

@Module({ generateMutationSetters: true })
class Auth extends VuexModule {
  private userData: any = JSON.parse(localStorage.getItem('userData') ?? '{}')
  private _token: string | null = localStorage.getItem(StoreNames.AUTH_TOKEN)
  private _access_token: string | null = localStorage.getItem(
    StoreNames.ACCESS_TOKEN,
  )
  private _refresh_token: string | null = localStorage.getItem(
    StoreNames.REFRESH_TOKEN,
  )
  private _valid_intent = 0
  private _valid_intent_error = 0

  private _terms_conditions = Boolean(localStorage.getItem(StoreNames.TERMS))

  get user() {
    return this.userData
  }

  get Intent(): number {
    return this._valid_intent
  }

  get IntentError(): number {
    return this._valid_intent_error
  }

  get Roles(): { name: string }[] {
    if (!this._access_token) return []
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.groups
  }

  get Name(): string {
    if (!this._access_token) return 'Anonymous'
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.user_name
  }

  get ID(): string | null {
    if (!this._access_token) return null
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    return tokenJSON.user_id
  }

  get isValidToken(): boolean {
    if (!this._access_token) return false
    const tokenJSON = JSON.parse(atob(this._access_token.split('.')[1]))
    const exp = Number(tokenJSON.exp) * 1000
    const time = new Date().getTime()
    return exp > time
  }

  get Token(): string | null {
    return this._token
  }

  get AccessToken(): string | null {
    return this._access_token
  }

  get RefreshToken(): string | null {
    return this._refresh_token
  }

  get isAuthenticated(): boolean {
    return this._token !== null
  }

  @Mutation
  setIntent(payload: number | null) {
    if (payload) this._valid_intent = payload
    else this._valid_intent++
  }

  @Mutation
  setIntentError(payload: number | null) {
    if (payload !== null) this._valid_intent_error = payload
    else this._valid_intent_error++
  }

  @Mutation
  SET_USERDATA(userData: any) {
    this.userData = userData
  }
  @Mutation
  extractTokenData(jwt: string) {
    try {
      if (jwt) {
        const jwtData = atob(jwt.split('.')[1])
        this.userData = JSON.parse(jwtData)
        this.userData.terms_conditions = this.userData.terms_conditions
          ? true
          : this._terms_conditions
        localStorage.setItem('userData', JSON.stringify(this.userData))
      } else {
        this.userData = {}
      }
    } catch {
      this.userData = {}
    }
  }

  @Mutation
  setToken(payload: Token) {
    this._token = JSON.stringify(payload)
    this._access_token = payload.access
    this._refresh_token = payload.refresh
    this._terms_conditions = payload.terms_conditions || false

    localStorage.setItem(StoreNames.TERMS, String(this._terms_conditions))
    localStorage.setItem(StoreNames.AUTH_TOKEN, this._token)
    localStorage.setItem(StoreNames.ACCESS_TOKEN, this._access_token)
    localStorage.setItem(StoreNames.REFRESH_TOKEN, this._refresh_token)
  }

  @Mutation
  clearToken() {
    this.isAuthenticated
    this._token = null
    this._access_token = null
    this._refresh_token = null
    this._valid_intent = 0
    localStorage.removeItem(StoreNames.AUTH_TOKEN)
    localStorage.removeItem(StoreNames.REFRESH_TOKEN)
    localStorage.removeItem(StoreNames.ACCESS_TOKEN)
    menuModule.clear()
    collectionModule.clear()
    matrixModule.clear()
  }

  @Action
  async login(payload: Login) {
    const response = await http.post('account/token/', payload)

    this.setToken(response.data)
    if (this.Roles.length == 1) await menuModule.initMenu(this.Roles[0].name)
    return Promise.resolve(response.data)
  }

  @Action
  async logout() {
    console.log(this._access_token, 'logout')
    try {
      if (this._refresh_token)
        await http.post('account/token_logout/', {
          refresh_token: this._refresh_token,
        })
    } catch (error) {
      console.error(error)
    }
    await this.clearToken()
    localStorage.clear()
    router
      .push({
        path: '/',
        query: {
          nextUrl: router.currentRoute.path,
          message: i18n.t('toast.session_off').toString(),
        },
        replace: true,
      })
      .catch(console.error)
  }

  @Action
  async refresh() {
    const response = await http.post('account/token/refresh/', {
      refresh: this._refresh_token,
    })
    if (
      this._refresh_token &&
      typeof response.data === 'object' &&
      'access' in response.data
    ) {
      const payload = {
        access: response.data.access,
        refresh: this._refresh_token,
        terms_conditions: response.data.terms_conditions,
      }
      this.setToken(payload)
      this.extractTokenData(this._token || '')
    }
  }
}

const authModule = new Auth({ store, name: 'auth' })
export default authModule
